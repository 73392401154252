/**
 * Individual music item page.
 */

import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Tags from "../components/tags";
import Sidebar from "../components/sidebar";
import Img from "gatsby-image"

const MusicTemplate = ({data, location}) => {

    const post = data.markdownRemark;
    const siteTitle = data.site.siteMetadata?.title || `Title`;

    let featuredImg = '';
    if (post.frontmatter.featuredImage) {
        let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid;
        featuredImg = <div className="post-image"><Img fluid={featuredImgFluid} /></div>;
    }

    return (
        <Layout location={location} title={siteTitle}>
            <Seo
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
            />

            <Sidebar quote={data.quote} music={data.music}>
                {featuredImg}
            </Sidebar>

            <div className='main-content'>
                <article
                    className="music-post content"
                    itemScope
                    itemType="http://schema.org/Article"
                >
                    <header>
                        <h1 itemProp="headline">{post.frontmatter.title}</h1>
                        <p>{post.frontmatter.date}</p>
                    </header>
                    <section
                        dangerouslySetInnerHTML={{__html: post.html}}
                        itemProp="articleBody"
                    />
                    <Tags tags={post.frontmatter.tags}/>
                </article>
            </div>
        </Layout>
    )
};

export default MusicTemplate

export const pageQuery = graphql`
  query MusicPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $quoteId: String
    $musicId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    quote: markdownRemark(id: { eq: $quoteId }) {
      id
      html
      frontmatter {
        title
        author
        source
        source_link
      }
    }
    music: markdownRemark(id: { eq: $musicId }) {
        id
        frontmatter {
          title
          slug
          featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
        html
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
